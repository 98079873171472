const today = new Date();
const thisYear = today.getFullYear();
const thisMonth = today.getMonth();
const lastMonth = thisMonth - 1 >= 0 ? thisMonth - 1 : 11;
const lastMonthYear = lastMonth === 11 ? thisYear - 1 : thisYear;
const formatMonth = (month) => (month + 1).toString().padStart(2, "0");
const thisMonthFormatted = formatMonth(thisMonth);
const lastMonthFormatted = formatMonth(lastMonth);

const thisMonthCharges = [
  {
    itemType: "Charge",
    itemName: "Ebike Trip Increment",
    assessedDate: `${thisYear}-${thisMonthFormatted}-01T10:14:38.44Z`,
    programName: "Indego",
    itemAmount: 2.376,
  },
  {
    itemType: "Charge",
    itemName: "Ebike Trip Increment",
    assessedDate: `${thisYear}-${thisMonthFormatted}-11T10:13:39.353Z`,
    programName: "Indego",
    itemAmount: 3.456,
  },
];

const lastMonthCharges = [
  {
    itemType: "Charge",
    itemName: "Membership Fee",
    assessedDate: `${lastMonthYear}-${lastMonthFormatted}-03T17:40:24.46Z`,
    programName: "Indego",
    itemAmount: 21.6,
  },
  {
    itemType: "Charge",
    itemName: "Ebike Trip Increment",
    assessedDate: `${lastMonthYear}-${lastMonthFormatted}-11T11:55:30.007Z`,
    programName: "Indego",
    itemAmount: 6.48,
  },
];

// This matches the format in account state, as processed by fetchStatements in storeOptions
const statements = {};
statements[`${thisYear}-${lastMonthFormatted}`] = thisMonthCharges;
statements[`${lastMonthYear}-${lastMonthFormatted}`] = lastMonthCharges;

export { statements };